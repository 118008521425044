export const getIcon = (type, status) => {
  let icon, title
  switch (status) {
    case 'u':
      icon = 'unstarted'
      title = 'Tarefa # não iniciada'
      break
    case 'c':
      icon = 'completed'
      title = 'Tarefa # finalizada'
      break
    case 's':
      icon = 'started'
      title = 'Tarefa # iniciada'
      break
    case 'b':
      icon = 'blocked'
      title = 'Tarefa # bloqueada'
      break
    case 'p':
      icon = 'pushed'
      title = 'Tarefa # empurrada para amanhã'
      break
    case 'x':
      icon = 'canceled'
      title = 'Tarefa # cancelada'
      break
    case 'ub':
      icon = 'unstarted_blocked'
      title = 'Tarefa # bloqueada antes mesmo de começar'
      break
  }
  icon = type === 'p' ? `${icon}_p` : icon
  title = type === 'p' ? title.replace('#', 'pessoal') : title.replace('#', 'profissional')

  return { icon, title }
}

export const getBucketName = bucket => {
  switch (bucket) {
    case 't': return 'Tarefas de hoje'
    case 'p': return 'Tarefas de amanhã'
    case 'b': return 'Arquivo de tarefas'
    default: return 'Arquivo desconhecido'
  }
}
